const mainDirectory = 'SupplyRequest'

export default {
	host: '/',
	getOrderHistory: `${mainDirectory}/GetOrderHistory`,
	getOrder: `${mainDirectory}/GetOrder`,
	getRecentHistoryByStore: `${mainDirectory}/GetRecentHistoryByStore`,
	getStoreList: `${mainDirectory}/GetStoreList`,
	getClassicItems: `${mainDirectory}/GetClassicItems`,
	getStoreItems: `${mainDirectory}/GetStoreItems`,
	getItemGroups: `${mainDirectory}/GetItemGroups`,
	getOrderStatuses: `${mainDirectory}/GetOrderStatuses`,
	submitSupplyRequest: `${mainDirectory}/SubmitSupplyRequest`,
	submitFulfillment: `${mainDirectory}/FulfillSupplyRequest`,
	cancelFulfillment: `${mainDirectory}/CancelSupplyRequest`,
	notificationHub: '/NotificationHub/Connection/WebSocket'
}
