import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const Home = () => import(/* webpackChunkName: "home-page" */ '@/views/HomePage/HomeView.vue')
const Order = () => import(/* webpackChunkName: "order-home" */ '@/views/OrderView/HomeView')
const Unauthorized = () => import(/* webpackChunkName: "unauthorized" */ '@/views/UnauthorizedView')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/views/NotFoundView')
const Maintenance = () => import(/* webpackChunkName: "maintenance" */ '@/views/MaintenanceView')

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Supply Request',
			cartButton: false,
			pageHeader: false,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/Unauthorized',
		name: 'Unauthorized',
		component: Unauthorized,
		meta: {
			title: 'Supply Request',
			cartButton: false
		}
	},
	{
		path: '/Maintenance',
		name: 'Maintenance',
		component: Maintenance,
		meta: {
			title: 'Maintenance Mode',
			cartButton: false,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/Order/:requestId',
		name: 'OrderHomePage',
		component: Order,
		meta: {
			pageHeader: false,
			user: ['admin', 'management']
		}
	},
	{
		path: '/NotFound',
		name: 'NotFound',
		component: NotFound,
		meta: {
			title: 'Supply Request',
			cartButton: false,
			user: ['admin', 'management', 'store']
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: NotFound,
		meta: {
			title: 'Supply Request',
			cartButton: false,
			user: ['admin', 'management', 'store']
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior() {
		return { top: 0, behavior: 'smooth' }
	}
})

router.beforeEach(to => {
	const pageHeader = typeof to.meta.pageHeader === 'undefined' ? true : to.meta.pageHeader
	store.commit('setPageHeader', pageHeader)
	store.commit('setPageTitle', to.meta.title || '')
	store.commit('setCartButton', to.meta.cartButton || false)
})

export default router
