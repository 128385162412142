import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { site } from './config/app.config'

import 'vue-skeletor/dist/vue-skeletor.css'
import 'vue-universal-modal/dist/index.css'
import VueScreen from 'vue-screen'
import { Skeletor } from 'vue-skeletor'
import VueUniversalModal from 'vue-universal-modal'
import vClickOutside from 'click-outside-vue3'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueScreen, 'bootstrap')
app.use(VueUniversalModal, { teleportTarget: site.modalContainer })
app.use(vClickOutside)

app.component(Skeletor.name, Skeletor)

app.mount(site.appContainer)
