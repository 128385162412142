import { appName, containerId } from '../../package.json'

export const api = {
	// Choose whether to send the result data back to the component
	sendSuccessResponse: true,
	// Show request's exception messages that are not in our ignoreExceptionByURL rule
	showExceptionMessage: true,
	// Exclude request's exception reporting for these endpoints
	ignoreExceptionByURL: [],
	// Show programmed error messages sent from server. This is sent from the server as {error: true, message: ...}
	showCustomErrorMessage: true,
	// Choose whether to show a success message
	showSuccessMessage: false
}

export const site = {
	// App mounting point
	appContainer: containerId.includes('#') ? containerId : `#${containerId}`,
	// App modals mounting point
	modalContainer: containerId.includes('#') ? `${containerId}-modals` : `#${containerId}-modals`,
	// Text to display on the return to uri query parameter
	URIReturnTo: 'return-to',
	// Local and session storage key prefix
	storageKeyPrefix: `__${appName}`
}

export default {
	...api,
	...site
}
