import { site } from '@/config/app.config'
import apiAction from '@/plugins/apiAction'

export default {
	namespaced: true,
	state: {
		data: null,
		statuses: null,
		storageKey: `${site.storageKeyPrefix}::Order`,
		ordersFetched: false,
		fetchingRequestHistory: false,
		fetchingOrderStatuses: false
	},
	getters: {},
	mutations: {
		setData(state, payload) {
			state.data = payload
		},
		setStatuses(state, payload) {
			state.statuses = payload
		}
	},
	actions: {
		async fetchRequestHistory({ state, commit }) {
			state.fetchingRequestHistory = true
			const requestHistory = await apiAction.fetchOrderHistory()

			if (requestHistory) {
				commit('setData', requestHistory)
			}

			state.fetchingRequestHistory = false
		},
		async fetchOrderStatuses({ state, commit }) {
			state.fetchingOrderStatuses = true
			const orderStatuses = await apiAction.fetchOrderStatuses()

			if (orderStatuses) {
				commit('setStatuses', orderStatuses)
			}

			state.fetchingOrderStatuses = false
		}
	}
}
