<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import VSvg from '@/components/VSvg'
import CartView from '@/views/PageHeader/CartView'

const vuex = useStore()
const title = computed(() => vuex.state.pageTitle)
const showPageHeader = computed(() => vuex.state.showPageHeader)
const showCartButton = computed(() => vuex.state.showCartButton)
const itemsInCart = computed(() => vuex.getters['items/cartCount'])
const viewCart = ref(false)
</script>

<template>
	<transition name="slow-fade" mode="out-in">
		<div v-if="showPageHeader">
			<div class="row mx-0 main-page-header-container justify-content-between">
				<div class="col px-0">
					<div>
						<transition name="fade" mode="out-in">
							<h1 class="main-page-header-title">{{ title }}</h1>
						</transition>
					</div>
				</div>
				<div class="col text-right" v-if="showCartButton">
					<div class="shopping-cart-button cursor-pointer" @click="viewCart = !viewCart">
						<v-svg icon="cart" size="22" offset />
						<div class="shopping-cart-text disable-selection">Cart</div>
						<div class="shopping-cart-count">{{ itemsInCart }}</div>
					</div>
				</div>
			</div>
			<cart-view v-show="viewCart" />
		</div>
	</transition>
</template>

<style lang="scss" scoped>
@import 'src/scss/variables';

.main-page-header-container {
	min-height: 80px;
	height: 80px;
	max-height: 80px;
	margin-top: -25px;

	.col,
	.col-auto {
		margin: auto 0;
	}
}

.shopping-cart-button {
	background-color: $primary;
	color: $white;
	border-radius: $border-radius;
	box-shadow: map-get($shadows, 2);
	padding: 0.7rem 1.6rem;
	position: absolute;
	top: -1.5rem;
	right: 1rem;
	width: fit-content;
	text-align: left;

	.shopping-cart-text {
		font-size: 1.55rem;
		display: inline-block;
		padding-left: 1.2rem;
	}

	.shopping-cart-count {
		position: absolute;
		font-size: 1.45rem;
		font-weight: 500;
		border-radius: $button-border-radius;
		width: fit-content;
		background-color: $dark-blue;
		color: $white;
		padding: 0.3rem 0.4rem 0 0.4rem;
		top: -0.8rem;
		right: -1.2rem;
		min-width: 2.5rem;
		min-height: 2.5rem;
		text-align: center;
	}
}

@media only screen and (min-width: $breakpoint-md) {
	.main-page-header-title {
		font-size: 2.4rem;
		font-weight: 500;
	}
}

@media only screen and (max-width: $breakpoint-md) {
	.main-page-header-title {
		font-size: 2.2rem;
		font-weight: 500;
		margin-top: 1rem;
	}
}
</style>
