import api from '@/plugins/api'
import apiEndpoints from '@/config/api.endpoints'
import snackbar from '@/plugins/snackbar'

const stringFetch = async (endpoint, jsonError, defaultError) => {
	return await api
		.get(endpoint)
		.then(res => {
			try {
				return JSON.parse(res)
			} catch {
				snackbar.error(jsonError)
				return null
			}
		})
		.catch(err => {
			snackbar.error(`${defaultError} Error: ${err}`)
			return null
		})
}

const jsonFetch = async (endpoint, defaultError) => {
	return await api
		.get(endpoint)
		.then(res => {
			return res
		})
		.catch(err => {
			snackbar.error(`${defaultError} Error: ${err}`)
			return null
		})
}

const jsonPost = async (endpoint, data, defaultError) => {
	return await api
		.preventDefaultExceptionMessage()
		.post(endpoint, data)
		.then(res => {
			return res
		})
		.catch(err => {
			if (defaultError !== null) {
				snackbar.error(`${defaultError} Error: ${err}`)
			}

			return null
		})
}

export default {
	async fetchStoreList() {
		return stringFetch(
			apiEndpoints.getStoreList,
			'Failed to retrieve list of stores. Error: Invalid store list',
			'Failed to retrieve list of stores.'
		)
	},
	async fetchOrder(data) {
		return await jsonPost(apiEndpoints.getOrder, data, null)
	},
	async fetchRecentHistoryByStore(data) {
		return await jsonPost(apiEndpoints.getRecentHistoryByStore, data, 'Failed to retrieve recent history.')
	},
	async fetchOrderHistory() {
		return await jsonFetch(apiEndpoints.getOrderHistory, 'Failed to retrieve request history.')
	},
	async fetchOrderStatuses() {
		return await jsonFetch(apiEndpoints.getOrderStatuses, 'Failed to retrieve order statuses.')
	},
	async fetchClassicItems() {
		return await jsonFetch(apiEndpoints.getClassicItems, 'Failed to retrieve list of items.')
	},
	async fetchStoreItems() {
		return await jsonFetch(apiEndpoints.getStoreItems, 'Failed to retrieve list of items.')
	},
	async fetchItemGroups() {
		return await jsonFetch(apiEndpoints.getItemGroups, 'Failed to retrieve item groups.')
	}
}
