const CryptoJS = require('crypto-js')

const key = (l = 24, x = '') => {
	x += Math.random().toString(20).substr(2, l)
	if (x.length > l) return x.slice(0, l)
	return key(l, x)
}

const iv = CryptoJS.enc.Hex.parse('576E5A7234753777217A25432A462D4A')
const sGenKey = CryptoJS.enc.Utf8.parse('_SupplyRequest::')
const Salt = CryptoJS.enc.Utf8.parse('B5AF2898-455D-4B93-B386-CF443A87E2D6')
const key1 = key()
const key2 = key()
const key3 = key()

export default {
	encrypt1: (text, key = key1) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(text, key).toString())),
	decrypt1: (encryption, key = key1) =>
		CryptoJS.AES.decrypt(CryptoJS.enc.Base64.parse(encryption).toString(CryptoJS.enc.Utf8), key).toString(CryptoJS.enc.Utf8),

	encrypt2: (text, key = key2) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.Rabbit.encrypt(text, key).toString())),
	decrypt2: (encryption, key = key2) =>
		CryptoJS.Rabbit.decrypt(CryptoJS.enc.Base64.parse(encryption).toString(CryptoJS.enc.Utf8), key).toString(CryptoJS.enc.Utf8),

	encrypt3: (text, key = key3) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.RC4Drop.encrypt(text, key).toString())),
	decrypt3: (encryption, key = key3) =>
		CryptoJS.RC4Drop.decrypt(CryptoJS.enc.Base64.parse(encryption).toString(CryptoJS.enc.Utf8), key).toString(CryptoJS.enc.Utf8),

	hash1: text => CryptoJS.SHA256(text).toString(),
	hash2: text => CryptoJS.HmacSHA1(text, key()).toString(),
	hash3: text => CryptoJS.SHA3(text, { outputLength: 224 }).toString(),

	httpDecrypt: encryption => {
		const i128BitKey = CryptoJS.PBKDF2(sGenKey.toString(CryptoJS.enc.Utf8), Salt, {
			keySize: 128 / 32,
			iterations: 1000
		})

		const cypherParams = CryptoJS.lib.CipherParams.create({
			ciphertext: CryptoJS.enc.Base64.parse(encryption)
		})

		const decrypted = CryptoJS.AES.decrypt(cypherParams, i128BitKey, {
			mode: CryptoJS.mode.CBC,
			iv: iv,
			padding: CryptoJS.pad.Pkcs7
		})

		return decrypted.toString(CryptoJS.enc.Utf8)
	}
}
