import { createStore } from 'vuex'
import user from '@/store/modules/user'
import stores from '@/store/modules/stores'
import items from '@/store/modules/items'
import orders from '@/store/modules/orders'

export default createStore({
	state: {
		pageTitle: 'Loading...',
		showPageHeader: true,
		maintenance: false,
		showCartButton: false,
		environment: null
	},
	getters: {
		maintenanceMode: state => state.maintenance,
		viewConcept: state => state.environment === 'local' || state.environment === 'dev'
	},
	mutations: {
		setPageHeader(state, status) {
			state.showPageHeader = status
		},
		setPageTitle(state, title) {
			state.pageTitle = title
		},
		setMaintenanceMode(state, status) {
			state.maintenance = status
		},
		setCartButton(state, status) {
			state.showCartButton = status
		},
		setEnvironment(state, env) {
			state.environment = env
		}
	},
	actions: {},
	modules: {
		user,
		stores,
		items,
		orders
	}
})
