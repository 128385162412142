<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import VSvg from '@/components/VSvg'
import VBtn from '@/components/VBtn'

const vuex = useStore()
const items = computed(() => vuex.getters['items/cartItems'])
</script>

<template>
	<div class="shopping-cart">
		<div class="cart-container">
			<div class="cart-title">
				<v-svg icon="cart" size="28" black offset />
				<span class="pl-3">SHOPPING CART</span>
			</div>
			<div class="item-list" v-if="items">
				<div class="py-4 mb-4" v-for="item in items" :key="`cart-item-${item.itemId}`">
					<div class="row mx-0">
						<div class="col-auto">
							<div style="width: 62px; height: 62px" :style="item.icon ? '' : 'background-color: #555'">
								<template v-if="item.icon">
									<i class="fa fa-3x" :class="item.icon" style="padding-top: 1.2rem; padding-left: 1rem" />
								</template>
								<template v-else> &nbsp; </template>
							</div>
						</div>
						<div class="col">
							<div>{{ item.itemName }}</div>
							<div>Qty: {{ item.cartQuantity }}</div>
							<div>Supplier: {{ item.supplierName }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="my-5 text-center" v-else>There are no items in your cart</div>
		</div>

		<div class="checkout-btn" v-if="items">
			<v-btn block soft-radius>Checkout</v-btn>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import 'src/scss/variables';

.shopping-cart {
	position: absolute;
	top: 7.3rem;
	right: 3.5rem;
	width: 425px;
	height: 600px;
	padding: 1rem 0;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: map-get($shadows, 2);
	z-index: 10;

	.cart-container {
		overflow: auto;
		height: 520px;
		padding: 0 1rem;

		.cart-title {
			font-size: 2.1rem;
			text-align: center;
			padding: 1rem 0;
			margin-bottom: 1rem;
		}
	}

	.checkout-btn {
		background-color: $white;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 1.5rem 1rem;
	}
}
</style>
