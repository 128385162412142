import { site } from '@/config/app.config'
import apiAction from '@/plugins/apiAction'

export default {
	namespaced: true,
	state: {
		data: null,
		classicData: null,
		storeData: null,
		itemGroups: null,
		storageKey: `${site.storageKeyPrefix}::Items`,
		classicDataFetched: false,
		storeDataFetched: false,
		itemGroupsFetched: false,
		cart: {}
	},
	getters: {
		all: state => state.data,
		cartCount: state => Object.values(state.cart)?.reduce((a, b) => a + b, 0) || 0,
		cartItems(state) {
			const itemsInCart = Object.keys(state.cart)
			if (itemsInCart.length > 0) {
				let result = []
				for (let item of itemsInCart) {
					const cartItem = state.storeData?.find(x => x.itemId === ~~item) || null
					if (cartItem) {
						cartItem.cartQuantity = state.cart[item]
						result.push(cartItem)
					}
				}

				return result
			} else {
				return false
			}
		}
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
		},
		setClassicData(state, payload) {
			state.classicData = payload
			state.classicDataFetched = true
		},
		setStoreData(state, payload) {
			state.storeData = payload
			state.storeDataFetched = true
		},
		setItemGroups(state, payload) {
			state.itemGroups = payload
			state.itemGroupsFetched = true
		},
		addItemToCart(state, payload) {
			state.cart[payload.itemId] = payload.qty
		},
		removeItemFromCart(state, itemId) {
			delete state.cart[itemId]
		},
		setCart(state, payload) {
			state.cart = payload
		}
	},
	actions: {
		setCart({ commit }, payload) {
			commit('setCart', payload)
		},
		updateCart({ commit }, payload) {
			commit('addItemToCart', payload)
		},
		removeFromCart({ commit }, itemId) {
			commit('removeItemFromCart', itemId)
		},
		clearCart({ commit }) {
			commit('setCart', {})
		},
		async fetchItemGroups({ state, commit }) {
			state.itemGroupsFetched = true
			const itemGroups = await apiAction.fetchItemGroups()

			if (itemGroups) {
				commit('setItemGroups', itemGroups)
			}

			state.itemGroupsFetched = false
		}
	}
}
