<script setup>
import { useStore } from 'vuex'
import { Vue3Snackbar as VSnackbar } from 'vue3-snackbar'
import HeaderView from '@/views/PageHeader/HeaderView'

const store = useStore()
store.dispatch('user/init')
</script>

<template>
	<transition name="slow-fade" mode="out-in">
		<div>
			<header-view />
			<router-view v-slot="{ Component }">
				<keep-alive>
					<transition name="slide" mode="out-in">
						<component :is="Component" />
					</transition>
				</keep-alive>
			</router-view>
			<teleport to="body">
				<v-snackbar bottom right dense :duration="4000" key="snackbar-message"></v-snackbar>
			</teleport>
		</div>
	</transition>
</template>

<style lang="scss">
@import 'src/scss/main.scss';
</style>
